<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ viewLogs ? $t("access logs") : $t("configs") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="viewLogs = !viewLogs" v-on="on">
            <v-icon>
              {{ viewLogs ? "mdi-tune" : "mdi-door-closed-lock" }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ viewLogs ? $t("show access configs") : $t("show access logs") }}
        </span>
      </v-tooltip>
      <wiki slug="access-logs" />
    </v-toolbar>
    <v-card-text>
      <rzo-access-logs
        v-if="viewLogs && $store.getters.tags.includes('acs-logs')"
        :product-id="productId"
        :racks="racks"
        class="mb-4"
      />
      <rzo-access-logs-realtime
        v-if="viewLogs && $store.getters.tags.includes('acs-logs-rt')"
        :product-id="productId"
        :cages="cages"
        class="mb-4"
      />
      <rzo-access-notify-configs
        v-if="!viewLogs"
        :racks="racks"
        :cages="cages"
        class="mb-4"
      />
      <rzo-access-report-configs
        v-if="!viewLogs"
        :racks="racks"
        :cages="cages"
        class="mb-4"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import RzoAccessLogs from "@/components/services/rzo/RzoAccessLogs";
import RzoAccessLogsRealtime from "@/components/services/rzo/RzoAccessLogsRealtime";
import RzoAccessNotifyConfigs from "@/components/services/rzo/RzoAccessNotifyConfigs";
import RzoAccessReportConfigs from "@/components/services/rzo/RzoAccessReportConfigs";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "RzoAccessLogsView",
  components: {
    RzoAccessLogs,
    RzoAccessLogsRealtime,
    RzoAccessNotifyConfigs,
    RzoAccessReportConfigs,
    Wiki,
  },
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: () => ({
    viewLogs: true,
    racks: [],
    cages: [],
  }),
  watch: {
    "$store.state.session": function () {
      this.getRacks();
    },
    "$store.state.session": function () {
      this.getCages();
    },
  },
  methods: {
    getRacks() {
      if (this.$store.getters.tags.includes("acs-logs")) {
        var that = this;
        this.$http
          .get("services/rzo/access/logs/racks", {
            params: {
              product_id: this.productId,
            },
          })
          .then((response) => {
            that.racks = response.data;
          })
          .catch((error) => {
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
          });
      }
    },
    getCages() {
      if (this.$store.getters.tags.includes("acs-logs-rt")) {
        var that = this;
        this.$http
          .get("services/rzo/access/logs-realtime/cages", {
            params: {
              product_id: this.productId,
            },
          })
          .then((response) => {
            that.cages = response.data;
          })
          .catch((error) => {
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
          });
      }
    },
  },
  mounted() {
    this.getRacks();
    this.getCages();
  },
};
</script>

<i18n>
{
  "en": {
    "access logs": "Access logs",
    "configs": "Configs",
    "show access configs": "show configs",
    "show access logs": "show access logs"
  }, 
  "de": {
    "access logs": "Zugangsprotokoll",
    "configs": "Einstellungen",
    "show access configs": "zeige Zugangs Einstellungen",
    "show access logs": "zeige Zugangsprotokoll"
  }
}
</i18n>
