<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("notify") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="dialog = !dialog" :disabled="loading" icon>
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add entry") }}</span>
      </v-tooltip>
      <wiki slug="access-notify" />
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :expanded="expanded"
        :mobile-breakpoint="mbreakpoint"
        sort-by="id"
        sort-desc
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
        single-expand
        @item-expanded="dialog = false"
      >
        <template v-slot:top>
          <rzo-access-notify-config-details
            v-if="dialog"
            :id.sync="item.id"
            :rack.sync="item.rack"
            :email.sync="item.email"
            :notify-email.sync="item.notify_email"
            :mobile.sync="item.mobile"
            :notify-mobile.sync="item.notify_mobile"
            :default.sync="item.default"
            :racks="racks"
            :cages="cages"
            :items="items"
            @created="
              expanded = [];
              dialog = false;
              getItems();
            "
            @canceled="
              expanded = [];
              dialog = false;
            "
          />
          <v-text-field
            v-else
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            clearable
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:item.rack="{ item }">
          {{ item.rack ? item.rack : $t("all") }}
        </template>

        <template v-slot:item.email="{ item }">
          <email-list-input v-model="item.email" disabled />
        </template>

        <template v-slot:item.notify_email="{ item }">
          <v-simple-checkbox v-model="item.notify_email" disabled />
        </template>

        <template v-slot:item.notify_mobile="{ item }">
          <v-simple-checkbox v-model="item.notify_mobile" disabled />
        </template>

        <template v-slot:item.mobile="{ item }">
          <phone-list-input v-model="item.mobile" disabled />
        </template>

        <template v-slot:item.default="{ item }">
          <v-simple-checkbox v-model="item.default" disabled />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <rzo-access-notify-config-details
              :id.sync="item.id"
              :rack.sync="item.rack"
              :email.sync="item.email"
              :notify-email.sync="item.notify_email"
              :mobile.sync="item.mobile"
              :notify-mobile.sync="item.notify_mobile"
              :default.sync="item.default"
              :racks="racks"
              :cages="cages"
              :items="items"
              @deleted="
                expanded = [];
                getItems();
              "
              @updated="expanded = []"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import BooleanValue from "@/components/basics/BooleanValue";
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import isMobile from "@/utils/mixins/isMobile";
import RzoAccessNotifyConfigDetails from "@/components/services/rzo/RzoAccessNotifyConfigDetails";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "RzoAccessNotifyConfigs",
  components: {
    BooleanValue,
    EmailListInput,
    PhoneListInput,
    RzoAccessNotifyConfigDetails,
    Wiki,
  },
  mixins: [isMobile],
  props: {
    racks: {
      type: Array,
      required: true,
    },
    cages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    item: {
      id: null,
      rack: null,
      email: "",
      notify_email: false,
      mobile: "",
      notify_mobile: false,
      default: true,
    },
    expanded: [],
    items: [],
    search: "",
    loading: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("rack"),
          value: "rack",
        },
        {
          text: this.$i18n.t("email"),
          value: "email",
        },
        {
          text: this.$i18n.t("notify email"),
          value: "notify_email",
        },
        {
          text: this.$i18n.t("mobile"),
          value: "mobile",
        },
        {
          text: this.$i18n.t("notify mobile"),
          value: "notify_mobile",
        },
        {
          text: this.$i18n.t("default"),
          value: "default",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.items = [];
      this.getItems();
    },
    dialog() {
      if (this.dialog) this.expanded = [];
    },
  },
  methods: {
    getItems() {
      var that = this;
      this.$http
        .get("services/rzo/access/notify-configs")
        .then((response) => {
          that.items = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "notify": "Notify",
    "all": "All",
    "rack": "Rack",
    "email": "Email",
    "notify email": "Send email",
    "mobile": "Mobile",
    "notify mobile": "Send SMS",
    "default": "Default",
    "add entry": "add entry"
  }, 
  "de": {
    "notify": "Benachrichtigung",
    "all": "Alle",
    "rack": "Rack",
    "email": "Email",
    "notify email": "Sende Email",
    "mobile": "Mobil",
    "notify mobile": "Sende SMS",
    "default": "Default",
    "add entry": "Eintrag hinzufügen"
  }
}
</i18n>
