<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("reports") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="dialog = !dialog" :disabled="loading" icon>
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add entry") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :expanded="expanded"
        :mobile-breakpoint="mbreakpoint"
        sort-by="id"
        sort-desc
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
        single-expand
        @item-expanded="dialog = false"
      >
        <template v-slot:top>
          <rzo-access-report-config-details
            v-if="dialog"
            :id.sync="item.id"
            :rack.sync="item.rack"
            :email.sync="item.email"
            :frequency.sync="item.frequency"
            :racks="racks"
            :cages="cages"
            :items="items"
            @created="
              expanded = [];
              dialog = false;
              getItems();
            "
            @canceled="
              expanded = [];
              dialog = false;
            "
          />
          <v-text-field
            v-else
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            clearable
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:item.rack="{ item }">
          {{ item.rack ? item.rack : $t("all") }}
        </template>

        <template v-slot:item.email="{ item }">
          <email-list-input v-model="item.email" disabled />
        </template>

        <template v-slot:item.frequency="{ item }">
          {{ $t(item.frequency) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <rzo-access-report-config-details
              :id.sync="item.id"
              :rack.sync="item.rack"
              :email.sync="item.email"
              :frequency.sync="item.frequency"
              :racks="racks"
              :cages="cages"
              :items="items"
              @deleted="
                expanded = [];
                getItems();
              "
              @updated="expanded = []"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import BooleanValue from "@/components/basics/BooleanValue";
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import isMobile from "@/utils/mixins/isMobile";
import RzoAccessReportConfigDetails from "@/components/services/rzo/RzoAccessReportConfigDetails";

export default {
  name: "RzoAccessReportConfigs",
  components: {
    BooleanValue,
    EmailListInput,
    PhoneListInput,
    RzoAccessReportConfigDetails,
  },
  mixins: [isMobile],
  props: {
    racks: {
      type: Array,
      required: true,
    },
    cages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    item: {
      id: null,
      rack: null,
      email: "",
      notify_email: false,
      mobile: "",
      notify_mobile: false,
      default: true,
    },
    expanded: [],
    items: [],
    search: "",
    loading: false,
    frequencies: ["daily", "weekly", "monthly"],
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("rack"),
          value: "rack",
        },
        {
          text: this.$i18n.t("email"),
          value: "email",
        },
        {
          text: this.$i18n.t("frequency"),
          value: "frequency",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.items = [];
      this.getItems();
    },
    dialog() {
      if (this.dialog) this.expanded = [];
    },
  },
  methods: {
    getItems() {
      var that = this;
      this.$http
        .get("services/rzo/access/report-configs")
        .then((response) => {
          that.items = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "reports": "Reports",
    "all": "All",
    "rack": "Rack",
    "email": "Email",
    "frequency": "Frequency",
    "add entry": "add entry",
    "daily": "daily",
    "weekly": "weekly",
    "monthly": "monthly"
  }, 
  "de": {
    "reports": "Reports",
    "all": "Alle",
    "rack": "Rack",
    "email": "Email",
    "frequency": "Wiederholung",
    "add entry": "Eintrag hinzufügen",
    "daily": "täglich",
    "weekly": "wöchentlich",
    "monthly": "monatlich"
  }
}
</i18n>
