var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-4 mb-4",attrs:{"outlined":""}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":""}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.logs,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"time","sort-desc":"","hide-default-footer":_vm.logs.length <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{attrs:{"label":_vm.$t('change query'),"dense":""},on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}}),(_vm.showDetails)?_c('v-range-slider',{attrs:{"min":"-180","max":"180","label":_vm.$t('wish open entries') +
            ' ' +
            _vm.$t('from') +
            ' ' +
            _vm.formatDateTime(_vm.timeAfter) +
            ' ' +
            _vm.$t('to') +
            ' ' +
            _vm.formatDateTime(_vm.timeBefore),"loading":_vm.loading,"thumb-label":"","thumb-size":"42","dense":""},on:{"change":function($event){return _vm.getLogs()}},scopedSlots:_vm._u([{key:"thumb-label",fn:function({ value }){return [_vm._v(" "+_vm._s(value + "m")+" ")]}}],null,false,2538681009),model:{value:(_vm.timeRange),callback:function ($$v) {_vm.timeRange=$$v},expression:"timeRange"}}):_vm._e()]},proxy:true},{key:"item.time",fn:function({ item }){return [_c('div',{staticClass:"monospace text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatDateTime(item.time))+" ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }