<template>
  <v-card class="mt-4 mb-4" outlined>
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <v-row align="baseline" justify="center">
          <v-col cols="3">
            <validation-provider
              vid="rack"
              :name="$t('rack')"
              rules="max:10"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="localRack"
                :items="racksWithNone"
                item-text="text"
                item-value="value"
                name="rack"
                :label="$t('rack')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                chips
              />
            </validation-provider>
          </v-col>
          <v-col cols="3">
            <validation-provider
              vid="frequency"
              :name="$t('frequency')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="localFrequency"
                :items="frequencies"
                name="frequency"
                :label="$t('frequency')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                chips
              >
                <template v-slot:item="{ item }"> {{ $t(item) }} </template>
                <template v-slot:selection="{ item }">
                  {{ $t(item) }}
                </template>
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <email-list-input
              v-model="localEmail"
              name="email"
              :label="$t('email')"
              max-length="1024"
              multiple
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="id"
          :loading="loading"
          :disabled="loading"
          @click="deleteItem"
          text
        >
          {{ $t("delete") }}
        </v-btn>
        <v-btn
          v-if="!id"
          :loading="loading"
          :disabled="loading"
          @click="$emit('canceled')"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="invalid || loading || isUnChanged"
          @click="id == null ? createItem() : saveItem()"
          text
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "RzoAccessReportConfigDetails",
  components: {
    EmailListInput,
    PhoneListInput,
  },
  mixins: [formatPhoneNumber, showErrors, isMobile],
  props: {
    id: {
      validator: (prop) => typeof prop === "number" || prop === null,
      required: true,
      default: null,
    },
    rack: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
      default: null,
    },
    email: {
      type: String,
      required: false,
      default: "",
    },
    frequency: {
      type: String,
      required: true,
      default: "daily",
    },
    racks: {
      type: Array,
      default() {
        return [];
      },
    },
    cages: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      localId: this.id,
      localRack: this.rack,
      localEmail: this.email,
      localFrequency: this.frequency,
      frequencies: ["daily", "weekly", "monthly"],
    };
  },
  computed: {
    racksAndCages() {
      return [...this.racks, ...this.cages];
    },
    isUnChanged() {
      return (
        this.localRack == this.rack &&
        this.localEmail == this.email &&
        this.localFrequency == this.frequency
      );
    },
    racksWithNone() {
      return [
        {
          text: this.$i18n.t("all"),
          value: null,
        },
        ...this.racksAndCages.map(function (item) {
          return {
            text: item.name,
            value: item.name,
          };
        }),
      ];
    },
  },
  watch: {
    id() {
      this.localId = this.id;
    },
    rack() {
      this.localRack = this.rack;
    },
    email() {
      this.localEmail = this.email;
    },
    frequency() {
      this.localFrequency = this.frequency;
    },
  },
  methods: {
    saveItem() {
      var that = this;
      this.loading = true;
      this.$http
        .patch("services/rzo/access/report-configs/" + this.id, {
          rack: this.localRack,
          email: this.localEmail,
          frequency: this.localFrequency,
        })
        .then((response) => {
          that.$emit("update:id", response.data.id);
          that.$emit("update:rack", response.data.rack);
          that.$emit("update:email", response.data.email);
          that.$emit("update:frequency", response.data.frequency);
          that.$emit("updated");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    createItem() {
      var that = this;
      this.loading = true;
      this.$http
        .post("services/rzo/access/report-configs", {
          rack: this.localRack,
          email: this.localEmail,
          frequency: this.localFrequency,
        })
        .then((response) => {
          that.$emit("created");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    deleteItem() {
      var that = this;
      this.loading = true;
      this.$http
        .delete("services/rzo/access/report-configs/" + this.id)
        .then((response) => {
          that.$emit("deleted");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "rack": "Rack",
    "notify email": "Send email",
    "email": "Email",
    "frequency": "Frequency",
    "all": "All",
    "save": "Save",
    "delete": "Delete",
    "daily": "daily",
    "weekly": "weekly",
    "monthly": "monthly"
  },
  "de": {    
    "rack": "Rack",
    "notify email": "Sende Email",
    "email": "Email",
    "frequency": "Wiederholung",
    "all": "Alle",
    "save": "Speichern",
    "delete": "Löschen",
    "daily": "täglich",
    "weekly": "wöchentlich",
    "monthly": "monatlich"
  }
}
</i18n>
