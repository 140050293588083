<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :loading="loadingPdf"
            :disabled="loadingPdf"
            @click="downloadPdf"
            icon
          >
            <v-icon>$PdfDownload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("download logs as PDF") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <date-picker
            v-model="interval"
            :allowedDates="allowedDates"
            today
            range
            sortRange
            icon
            v-on="on"
          />
        </template>
        <span>{{ $t("change interval") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            :disabled="loading"
            :loading="loading"
            @click="getLogs()"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredLogs"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        sort-by="event_time"
        sort-desc
        :hide-default-footer="filteredLogs.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="rack"
                :items="racks"
                item-text="name"
                item-value="id"
                :label="$t('rack')"
                chips
                dense
                :clearable="!isMobile"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="category"
                :items="categories"
                :label="$t('category')"
                chips
                multiple
                dense
                :clearable="!isMobile"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-outer-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                :clearable="!isMobile"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.event_time="{ item }">
          <div class="monospace text-no-wrap">
            {{ formatDateTime(item.event_time) }}
          </div>
        </template>

        <template v-slot:item.event_category="{ item }">
          {{ $t(item.event_category) }}
        </template>

        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
          <v-btn
            v-if="item.event_category == 'Captouch'"
            @click="expand(!isExpanded)"
            icon
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <rzo-access-logs-wish-open
              :product-id="item.product_id"
              :time="item.event_time"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import downloadFile from "@/utils/mixins/downloadFile";
import DatePicker from "@/components/basics/DatePicker";
import formatDateTime from "@/utils/mixins/formatDateTime";
import RzoAccessLogsWishOpen from "@/components/services/rzo/RzoAccessLogsWishOpen";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "RzoAccessLogs",
  components: {
    DatePicker,
    RzoAccessLogsWishOpen,
  },
  mixins: [downloadFile, formatDateTime, isMobile],
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    racks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localProductId: this.productId,
      logs: [],
      loading: false,
      loadingPdf: false,
      search: "",
      interval: [
        this.$moment().add(-30, "days").format("YYYY-MM-DD"),
        this.$moment().format("YYYY-MM-DD"),
      ],
      rack: null,
      category: [],
      categories: [
        "Closing",
        "Opening",
        "Captouch",
        "Timeout",
        "Opening (Unexpected)",
        "Opening (Try)",
        "Opening (Mastercard)",
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("time"),
          value: "event_time",
        },
        {
          text: this.$i18n.t("number"),
          value: "event_number",
        },
        {
          text: this.$i18n.t("message"),
          value: "message",
        },
        {
          text: this.$i18n.t("category"),
          value: "event_category",
        },
        {
          text: this.$i18n.t("opener card ID"),
          value: "opener_card_id",
        },
        {
          text: this.$i18n.t("rack"),
          value: "rack",
        },
        {
          text: this.$i18n.t("description"),
          value: "handle_descr",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    filteredLogs() {
      var that = this;
      this.category; // force re-compute
      this.logs;
      return this.logs.filter(function (l) {
        return (
          that.category.length == 0 || that.category.includes(l.event_category)
        );
      });
    },
  },
  watch: {
    "$store.state.session": function () {
      this.localProductId = this.productId;
      this.logs = [];
      this.getLogs();
    },
    productId(value) {
      this.localProductId = value;
    },
    interval() {
      this.getLogs();
    },
    rack(value) {
      this.localProductId = value;
      this.getLogs();
    },
  },
  methods: {
    allowedDates() {
      return true;
    },
    getLogs() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/rzo/access/logs", {
          params: {
            product_id: this.localProductId,
            time_after:
              this.interval.length > 0 ? this.interval[0] + " 00:00:00" : null,
            time_before:
              this.interval.length > 1 ? this.interval[1] + " 23:59:59" : null,
          },
        })
        .then((response) => {
          that.logs = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    downloadPdf() {
      var that = this;
      this.loadingPdf = true;
      this.downloadFile(
        "services/rzo/access/logs/captouches",
        "get",
        {
          product_id: this.localProductId,
          time_after:
            this.interval.length > 0 ? this.interval[0] + " 00:00:00" : null,
          time_before:
            this.interval.length > 1 ? this.interval[1] + " 23:59:59" : null,
        },
        "access-log.pdf",
        "application/pdf",
        function () {
          that.loadingPdf = false;
        },
        function () {},
        true
      );
    },
  },
  mounted() {
    this.getLogs();
  },
};
</script>

<i18n>
{
  "en": {
    "reload": "reload",
    "change interval": "change interval",
    "download logs as PDF": "download logs as PDF",
    "rack": "Rack",
    "time": "Time",
    "number": "Event number",
    "message": "Message",
    "category": "Category",
    "opener card ID": "Opener card ID",
    "description": "Description",
    "rack": "Rack",
    "change interval": "change interval",
    "Captouch": "rack handle actuated",
    "Opening": "open",
    "Closing": "closed",
    "Opening (Mastercard)": "open RZO-Support",
    "Opening (Unexpected)": "error or burglary",
    "Remote-Opening": "remote open",
    "Remote-Opening (Try)": "remote open (try)",
    "Remote-Closing": "remote closed"
  },
  "de": {
    "reload": "neu laden",
    "change interval": "Interval ändern",
    "download logs as PDF": "Protokoll als PDF laden",
    "rack": "Rack",
    "time": "Zeit",
    "number": "Nummer",
    "message": "Nachricht",
    "category": "Kategorie",
    "opener card ID": "Öffner Karten ID",
    "description": "Beschreibung",
    "rack": "Rack",
    "change interval": "Interval ändern",
    "Captouch": "Rackgriff betätigt",
    "Opening": "geöffnet",
    "Closing": "geschlossen",
    "Opening (Mastercard)": "geöffnet RZO-Support",
    "Opening (Unexpected)": "Fehler oder Aufbruch",
    "Remote-Opening": "fern geöffnet",
    "Remote-Opening (Try)": "fern geöffnet (Versuch)",
    "Remote-Closing": "fern geschlossen"
  }
}
</i18n>
