var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("reports"))+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("add entry")))])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"expanded":_vm.expanded,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"id","sort-desc":"","hide-default-footer":_vm.items.length <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      },"show-expand":"","single-expand":""},on:{"item-expanded":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.dialog)?_c('rzo-access-report-config-details',{attrs:{"id":_vm.item.id,"rack":_vm.item.rack,"email":_vm.item.email,"frequency":_vm.item.frequency,"racks":_vm.racks,"cages":_vm.cages,"items":_vm.items},on:{"update:id":function($event){return _vm.$set(_vm.item, "id", $event)},"update:rack":function($event){return _vm.$set(_vm.item, "rack", $event)},"update:email":function($event){return _vm.$set(_vm.item, "email", $event)},"update:frequency":function($event){return _vm.$set(_vm.item, "frequency", $event)},"created":function($event){_vm.expanded = [];
            _vm.dialog = false;
            _vm.getItems();},"canceled":function($event){_vm.expanded = [];
            _vm.dialog = false;}}}):_c('v-text-field',{staticClass:"mb-4",attrs:{"append-outer-icon":"mdi-magnify","clear-icon":"mdi-close-circle","clearable":"","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.rack",fn:function({ item }){return [_vm._v(" "+_vm._s(item.rack ? item.rack : _vm.$t("all"))+" ")]}},{key:"item.email",fn:function({ item }){return [_c('email-list-input',{attrs:{"disabled":""},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]}},{key:"item.frequency",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.frequency))+" ")]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('rzo-access-report-config-details',{attrs:{"id":item.id,"rack":item.rack,"email":item.email,"frequency":item.frequency,"racks":_vm.racks,"cages":_vm.cages,"items":_vm.items},on:{"update:id":function($event){return _vm.$set(item, "id", $event)},"update:rack":function($event){return _vm.$set(item, "rack", $event)},"update:email":function($event){return _vm.$set(item, "email", $event)},"update:frequency":function($event){return _vm.$set(item, "frequency", $event)},"deleted":function($event){_vm.expanded = [];
              _vm.getItems();},"updated":function($event){_vm.expanded = []}}})],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }