<template>
  <v-menu v-model="menu" :close-on-click="false" :close-on-content-click="true">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        :close="!disabled"
        outlined
        v-on="on"
        v-bind="attrs"
        @click="
          menu = selected == null ? true : true;
          $emit('update:selected', selected == null ? index : null);
        "
        @keyup.enter="
          menu = selected == null ? true : true;
          $emit('update:selected', selected == null ? index : null);
        "
        @click:close="$emit('delete', index)"
      >
        {{ phones[index] }}
      </v-chip>
    </template>
    <validation-provider
      ref="prov"
      id="phone"
      :name="$t('phone')"
      :rules="
        rules 
        ? rules 
        : 'required|phone' + (
            countryCode != null
              ? ':' + countryCode
              : ''
          ) +
          (
            maxLength != null && maxLength != ''
            ? '|max:' + (maxLength - currentLength - separator.length)
            : '')
      "
      v-slot="{ errors, classes, valid }"
    >
      <v-card width="360">
        <v-card-text>
          <v-text-field
            v-model="value"
            name="phone"
            :label="$t('phone')"
            :placeholder="$t('new number')"
            :error-messages="errors"
            :class="classes"
            :maxlength="
              maxLength != null && maxLength != ''
                ? maxLength - currentLength - separator.length
                : ''
            "
            :counter="
              maxLength != null && maxLength != ''
                ? maxLength - currentLength - separator.length
                : ''
            "
            @click.stop=""
            @blur="value = formatPhoneNumber(value, countryCode, locale)"
            @change="value = formatPhoneNumber(value, countryCode, locale)"
            @keyup.enter="valid && $emit('commit', value)"
            :clearable="!isMobile"
            flat
            solo
            autofocus
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('unselect', index)" text>
            {{ $t("discard") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!valid"
            @click="$emit('commit', value)"
            text
          >
            {{ $t("change") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-provider>
  </v-menu>
</template>

<script>
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "PhoneListInputChip",
  mixins: [formatPhoneNumber, isMobile],
  props: {
    index: {
      type: Number,
      required: true,
    },
    phones: {
      type: Array,
      required: true,
    },
    maxLength: {
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      required: true,
      default: null,
    },
    separator: {
      type: String,
      default: ";",
    },
    selected: {
      default: null,
      required: true,
    },
    disabled: {
      default: false,
      required: false,
    },
    rules: {
      type: String,
      default: "",
    },
    countryCode: {
      type: String,
      default: null,
    },
    locale: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    value: "",
    menu: false,
  }),
  computed: {
    active() {
      return this.selected == this.index;
    },
    currentLength() {
      this.index;
      var that = this;
      return this.phones
        .filter(function(e, index) {
          return that.index == null || that.index != index;
        })
        .join(this.separator).length;
    },
  },
  watch: {
    phones: {
      immediate: true,
      handler(value) {
        this.value = value[this.index] || "";
      },
    },
    selected: {
      immediate: true,
      handler(value) {
        this.menu = this.active;
      },
    },
    menu(value) {
      if (!value) this.$emit("update:selected", null);
    },
  },
  methods: {},
};
</script>

<i18n>
{
  "en": {
    "phone": "Phone",
    "new number": "New number",
    "discard": "Discard",
    "change": "Change"
  }, 
  "de": {
    "phone": "Telefon",
    "new number": "Neue Nummer",
    "discard": "Verwerfen",
    "change": "Ändern"
  }
}
</i18n>
