<template>
  <v-card class="mt-4 mb-4" outlined>
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <v-row align="baseline" justify="center" no-gutters>
          <v-col cols="12">
            <validation-provider
              vid="rack"
              :name="$t('rack')"
              rules="max:10"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="localRack"
                :items="racksWithNone"
                item-text="text"
                item-value="value"
                name="rack"
                :label="$t('rack')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                chips
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row align="baseline" justify="center" no-gutters>
          <v-col cols="4">
            <validation-provider
              vid="notify_email"
              :name="$t('notify email')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-switch
                v-model="localNotifyEmail"
                name="notify_email"
                :label="$t('notify email')"
                :error-messages="errors"
                :class="classes"
              />
            </validation-provider>
          </v-col>
          <v-col cols="8">
            <email-list-input
              v-model="localEmail"
              name="email"
              :label="$t('email')"
              max-length="1024"
              multiple
            />
          </v-col>
        </v-row>
        <v-row align="baseline" justify="center" no-gutters>
          <v-col cols="4">
            <validation-provider
              vid="notify_mobile"
              :name="$t('notify mobile')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-switch
                v-model="localNotifyMobile"
                name="notify_mobile"
                :label="$t('notify mobile')"
                :error-messages="errors"
                :class="classes"
              />
            </validation-provider>
          </v-col>
          <v-col cols="8">
            <validation-provider
              vid="mobile"
              :name="$t('mobile')"
              rules="phone"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <phone-list-input
                v-model="localMobile"
                :multiple="true"
                name="mobile"
                :label="$t('mobile')"
                max-length="100"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                @change="localMobile = formatPhoneNumber(localMobile)"
                @blur="localMobile = formatPhoneNumber(localMobile)"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="id"
          :loading="loading"
          :disabled="loading"
          @click="deleteItem"
          text
        >
          {{ $t("delete") }}
        </v-btn>
        <v-btn
          v-if="!id"
          :loading="loading"
          :disabled="loading"
          @click="$emit('canceled')"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="invalid || loading || isInvalid || isUnChanged"
          @click="id == null ? createItem() : saveItem()"
          text
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "RzoAccessNotifyConfigDetails",
  components: {
    EmailListInput,
    PhoneListInput,
  },
  mixins: [formatPhoneNumber, showErrors, isMobile],
  props: {
    id: {
      validator: (prop) => typeof prop === "number" || prop === null,
      required: true,
      default: null,
    },
    rack: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
      default: null,
    },
    email: {
      type: String,
      required: false,
      default: "",
    },
    notifyEmail: {
      type: Boolean,
      required: true,
      default: false,
    },
    mobile: {
      type: String,
      required: false,
      default: "",
    },
    notifyMobile: {
      type: Boolean,
      required: true,
      default: false,
    },
    default: {
      type: Boolean,
      required: true,
      default: false,
    },
    racks: {
      type: Array,
      default() {
        return [];
      },
    },
    cages: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      localId: this.id,
      localRack: this.rack,
      localEmail: this.email,
      localNotifyEmail: this.notifyEmail,
      localMobile: this.formatPhoneNumber(this.mobile),
      localNotifyMobile: this.notifyMobile,
      localDefault: this.default,
    };
  },
  computed: {
    racksAndCages() {
      return [...this.racks, ...this.cages];
    },
    isUnChanged() {
      return (
        this.localRack == this.rack &&
        this.localEmail == this.email &&
        this.localNotifyEmail == this.notifyEmail &&
        this.formatPhoneNumber(this.localMobile) ==
          this.formatPhoneNumber(this.mobile) &&
        this.localNotifyMobile == this.notifyMobile
      );
    },
    isInvalid() {
      return (
        // check email notify
        (this.localNotifyEmail &&
          (this.localEmail == null || this.localEmail == "")) ||
        // check mobile notify
        (this.localNotifyMobile &&
          (this.localMobile == null || this.localMobile == "")) ||
        // unique check default
        (this.defaultItem != null &&
          this.id != null &&
          this.defaultItem.id != this.id &&
          (this.localRack == null || this.localRack == ""))
        // unique check rack will be done by backend
      );
    },
    defaultItem() {
      this.items; // force recalculate
      var defaults = this.items.filter(function (i) {
        return i.default;
      });
      if (defaults.length > 0) return defaults[0];
      return null;
    },
    racksWithNone() {
      return [
        {
          text: this.$i18n.t("all"),
          value: null,
        },
        ...this.racksAndCages.map(function (item) {
          return {
            text: item.name,
            value: item.name,
          };
        }),
      ];
    },
  },
  watch: {
    id() {
      this.localId = this.id;
    },
    rack() {
      this.localRack = this.rack;
    },
    email() {
      this.localEmail = this.email;
    },
    notifyEmail() {
      this.localNotifyEmail = this.notifyEmail;
    },
    mobile() {
      this.localMobile = this.formatPhoneNumber(this.mobile);
    },
    notifyMobile() {
      this.localNotifyMobile = this.notifyMobile;
    },
    default() {
      this.localDefault = this.default;
    },
    localRack() {
      this.localDefault = this.localRack == null ? true : false;
    },
  },
  methods: {
    saveItem() {
      var that = this;
      this.loading = true;
      this.$http
        .patch("services/rzo/access/notify-configs/" + this.id, {
          rack: this.localRack,
          email: this.localEmail,
          notify_email: this.localNotifyEmail,
          mobile: this.localMobile,
          notify_mobile: this.localNotifyMobile,
        })
        .then((response) => {
          that.$emit("update:id", response.data.id);
          that.$emit("update:rack", response.data.rack);
          that.$emit("update:email", response.data.email);
          that.$emit("update:notifyEmail", response.data.notify_email);
          that.$emit("update:mobile", response.data.mobile);
          that.$emit("update:notifyMobile", response.data.notify_mobile);
          that.$emit("update:default", response.data.default);
          that.$emit("updated");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    createItem() {
      var that = this;
      this.loading = true;
      this.$http
        .post("services/rzo/access/notify-configs", {
          rack: this.localRack,
          email: this.localEmail,
          notify_email: this.localNotifyEmail,
          mobile: this.localMobile,
          notify_mobile: this.localNotifyMobile,
        })
        .then((response) => {
          that.$emit("created");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    deleteItem() {
      var that = this;
      this.loading = true;
      this.$http
        .delete("services/rzo/access/notify-configs/" + this.id)
        .then((response) => {
          that.$emit("deleted");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "rack": "Rack",
    "notify email": "Send email",
    "email": "Email",
    "notify mobile": "Send SMS",
    "mobile": "Mobile",
    "all": "All",
    "save": "Save",
    "delete": "Delete"
  },
  "de": {    
    "rack": "Rack",
    "notify email": "Sende Email",
    "email": "Email",
    "notify mobile": "Sende SMS",
    "mobile": "Mobil Nummer",
    "all": "Alle",
    "save": "Speichern",
    "delete": "Löschen"
  }
}
</i18n>
