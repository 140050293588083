<template>
  <validation-provider
    ref="prov"
    :vid="name"
    :name="label != null ? label : $t('phone')"
    :rules="
      rules != null
        ? rules
        : (required ? 'required' : '') +
          (maxLength != null && maxLength != '' ? '|max:' + maxLength : '') +
          (maxItems != null && maxItems != ''
            ? '|fake_max:' +
              currentLength +
              ',' +
              maxItems +
              ',' +
              $t('max. {max} phone numbers allowed', { max: maxItems })
            : '')
    "
    v-slot="{ errors, classes }"
  >
    <v-input
      v-model="input"
      :id="name"
      :label="label != null ? label : null"
      :hint="
        hint != null ? hint : multiple ? $t('multiple numbers possible') : ''
      "
      :error-messages="errors"
      :disabled="disabled"
      :class="classes"
      :prepend-icon="prependIcon"
      :persistent-hint="hint != null ? true : false"
    >
      <template v-slot:default>
        <v-chip-group column class="ml-4">
          <phone-list-input-chip
            v-for="(phone, i) in phones"
            :key="i"
            :index="i"
            :phones="phones"
            :maxLength="
              maxLength != null && maxLength != '' ? Number(maxLength) : null
            "
            :country-code="countryCode"
            :separator="separator"
            :selected.sync="selected"
            :disabled="disabled"
            :locale="locale"
            :rules="dialogRules"
            @commit="commitPhone(i, $event)"
            @delete="deletePhone($event)"
            @unselect="unselectPhone($event)"
          />
          <v-btn
            v-if="
              !disabled && selected == null && (phones.length == 0 || multiple)
            "
            :disabled="maxItems != null && phones.length >= Number(maxItems)"
            @click="
              phones.push('');
              selected = phones.length - 1;
            "
            fab
            x-small
            depressed
          >
            <v-icon>{{ appendIcon }} </v-icon>
          </v-btn>
        </v-chip-group>
      </template>
    </v-input>
  </validation-provider>
</template>

<script>
import PhoneListInputChip from "./PhoneListInputChip";

export default {
  name: "PhoneListInput",
  components: {
    PhoneListInputChip,
  },
  props: {
    value: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: null,
    },
    dialogRules: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "phone",
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    maxLength: {
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      required: false,
      default: null,
    },
    maxItems: {
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      required: false,
      default: null,
    },
    separator: {
      type: String,
      default: ";",
    },
    array: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: "mdi-plus",
    },
    countryCode: {
      type: String,
      default: null,
    },
    locale: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    phones: [],
    input: "",
    selected: null,
  }),
  computed: {
    currentLength() {
      return this.phones.join(this.separator).length;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.input = value;
          this.phones = this.toInternal(value);
        }
      },
    },
    phones(value) {
      this.input = this.toExternal(value);
      this.$refs.prov.validate();
    },
    selected(value) {
      if (this.selected == null)
        this.phones = this.phones.filter(function (e) {
          return (e != null) & (e != "");
        });
    },
  },
  methods: {
    toInternal(value) {
      var values = [];
      if (value == null || value == "") values = [];
      else if (this.array) values = value;
      else
        values = value.split(/[;,]+/).filter(function (e) {
          return e.trim() != "";
        });
      values = values.filter(function (e) {
        return e.trim() != "";
      });
      // remove duplicates
      return [...new Set(values)];
    },
    toExternal(value) {
      return value.join(this.separator);
    },
    commitPhone(i, value) {
      var phones = [...this.phones];
      phones[i] = value;
      this.phones = phones;
      this.selected = null;
      this.$emit("input", this.toExternal(this.phones));
    },
    deletePhone(i) {
      var phones = [...this.phones];
      phones.splice(i, 1);
      this.phones = phones;
      this.selected = null;
      this.$emit("input", this.toExternal(this.phones));
    },
    unselectPhone(i) {
      this.selected = null;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "phone": "Phone",
    "multiple numbers possible": "multiple numbers possible"
  }, 
  "de": {
    "phone": "Telefon",
    "multiple numbers possible": "mehrere Nummern möglich"
  }
}
</i18n>
