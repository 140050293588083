<template>
  <v-card class="mt-4 mb-4" outlined>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="logs"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        sort-by="time"
        sort-desc
        :hide-default-footer="logs.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:top>
          <v-switch
            :label="$t('change query')"
            @click="showDetails = !showDetails"
            dense
          />
          <v-range-slider
            v-if="showDetails"
            v-model="timeRange"
            min="-180"
            max="180"
            :label="
              $t('wish open entries') +
              ' ' +
              $t('from') +
              ' ' +
              formatDateTime(timeAfter) +
              ' ' +
              $t('to') +
              ' ' +
              formatDateTime(timeBefore)
            "
            :loading="loading"
            thumb-label
            thumb-size="42"
            @change="getLogs()"
            dense
          >
            <template v-slot:thumb-label="{ value }">
              {{ value + "m" }}
            </template>
          </v-range-slider>
        </template>
        <template v-slot:item.time="{ item }">
          <div class="monospace text-no-wrap">
            {{ formatDateTime(item.time) }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "RzoAccessLogsWishOpen",
  mixins: [formatDateTime, isMobile],
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    time: {
      type: String,
      required: false,
      default: null,
    },
    realtime: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    logs: [],
    showDetails: false,
    timeRange: [-3, 1],
    loading: false,
  }),
  computed: {
    timeAfter() {
      this.time; // force -re-compute
      this.timeRange;
      return this.$moment(this.time, "YYYY-MM-DD HH:mm:ss")
        .add(this.timeRange[0], this.realtime ? "days" : "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    timeBefore() {
      this.time; // force -re-compute
      this.timeRange;
      return this.$moment(this.time, "YYYY-MM-DD HH:mm:ss")
        .add(this.timeRange[1], this.realtime ? "days" : "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    headers() {
      return [
        {
          text: this.$i18n.t("time"),
          value: "time",
        },
        {
          text: this.$i18n.t("customer name"),
          value: "customer.customer_name",
        },
        {
          text: this.$i18n.t("first name"),
          value: "customer.first_name",
        },
        {
          text: this.$i18n.t("last name"),
          value: "customer.last_name",
        },
        {
          text: this.$i18n.t("company"),
          value: "customer.company",
        },
        {
          text: this.$i18n.t("rack"),
          value: "equipment.rack",
        },
        {
          text: this.$i18n.t("relay ACN"),
          value: "equipment.relay_acn",
        },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.logs = [];
      this.getLogs();
    },
  },
  methods: {
    getLogs: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/rzo/access/logs/wishopen", {
          params: {
            product_id: this.productId,
            time_after: this.timeAfter,
            time_before: this.timeBefore,
          },
        })
        .then((response) => {
          that.logs = response.data;
        })
        .catch((error) => {
          if (error.status === 400)
            console.log("ignore user error: ", error.message);
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getLogs();
  },
};
</script>

<i18n>
{
  "en": {
    "reload": "reload",
    "time": "Time",
    "customer name": "Customer name",
    "first name": "First name",
    "last name": "Last name",
    "company": "Company",
    "rack": "Rack",
    "relay ACN": "Relay ACN",
    "wish open entries": "Wish open entries",
    "from": "from",
    "to": "to",
    "change query": "Change query"
  },
  "de": {
    "reload": "neu laden",
    "time": "Zeit",
    "customer name": "Kundenname",
    "first name": "Vorname",
    "last name": "Nachname",
    "company": "Firma",
    "rack": "Rack",
    "relay ACN": "Relais ACN",
    "wish open entries": "Öffnungswunsch Einträge",
    "from": "von",
    "to": "bis",
    "change query": "Suche anpassen"
  }
}
</i18n>
