var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{ref:"prov",attrs:{"vid":_vm.name,"name":_vm.label != null ? _vm.label : _vm.$t('phone'),"rules":_vm.rules != null
      ? _vm.rules
      : (_vm.required ? 'required' : '') +
        (_vm.maxLength != null && _vm.maxLength != '' ? '|max:' + _vm.maxLength : '') +
        (_vm.maxItems != null && _vm.maxItems != ''
          ? '|fake_max:' +
            _vm.currentLength +
            ',' +
            _vm.maxItems +
            ',' +
            _vm.$t('max. {max} phone numbers allowed', { max: _vm.maxItems })
          : '')},scopedSlots:_vm._u([{key:"default",fn:function({ errors, classes }){return [_c('v-input',{class:classes,attrs:{"id":_vm.name,"label":_vm.label != null ? _vm.label : null,"hint":_vm.hint != null ? _vm.hint : _vm.multiple ? _vm.$t('multiple numbers possible') : '',"error-messages":errors,"disabled":_vm.disabled,"prepend-icon":_vm.prependIcon,"persistent-hint":_vm.hint != null ? true : false},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-chip-group',{staticClass:"ml-4",attrs:{"column":""}},[_vm._l((_vm.phones),function(phone,i){return _c('phone-list-input-chip',{key:i,attrs:{"index":i,"phones":_vm.phones,"maxLength":_vm.maxLength != null && _vm.maxLength != '' ? Number(_vm.maxLength) : null,"country-code":_vm.countryCode,"separator":_vm.separator,"selected":_vm.selected,"disabled":_vm.disabled,"locale":_vm.locale,"rules":_vm.dialogRules},on:{"update:selected":function($event){_vm.selected=$event},"commit":function($event){return _vm.commitPhone(i, $event)},"delete":function($event){return _vm.deletePhone($event)},"unselect":function($event){return _vm.unselectPhone($event)}}})}),(
            !_vm.disabled && _vm.selected == null && (_vm.phones.length == 0 || _vm.multiple)
          )?_c('v-btn',{attrs:{"disabled":_vm.maxItems != null && _vm.phones.length >= Number(_vm.maxItems),"fab":"","x-small":"","depressed":""},on:{"click":function($event){_vm.phones.push('');
            _vm.selected = _vm.phones.length - 1;}}},[_c('v-icon',[_vm._v(_vm._s(_vm.appendIcon)+" ")])],1):_vm._e()],2)]},proxy:true}],null,true),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }